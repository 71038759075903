import axios from 'axios';
import { EventEmitter } from 'events';
import { buffers, eventChannel, END } from 'redux-saga';
import { IFile } from './mediaTypes';

const getSignedUrl = (file: File) => {
  const data = {
    fileName: file.name,
    fileType: file.type,
  };
  return axios
    .post('/s3/get-signed-url', data)
    .then(data => data.data)
    .catch((e: any) => {
      throw e.response.data;
    });
};

const createUploaderChannel = (payload: any) => {
  return eventChannel(emit => {
    const file = payload.file;

    const onUploadProgress = (event: any) => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      console.log('onUploadProgress', percentCompleted);
      emit(percentCompleted);
    };

    const options = {
      headers: {
        'Content-Type': payload.file.name.split('.')[1],
      },
      onUploadProgress: onUploadProgress,
    };

    axios
      .put(payload.data.signedRequest, file, options)
      .then(() => {
        emit(END);
      })
      .catch(err => {
        emit(new Error(err.message));
        emit(END);
      });

    const unsubscribe = () => {};
    return unsubscribe;
  });
};

// const uploadFile = (payload: any) => {

//   const file = payload.file

//   const onUploadProgress = (event:any) => {
//     const percentCompleted = Math.round((event.loaded * 100) / event.total);
//     console.log('onUploadProgress', percentCompleted);
// };

//   const options = {
//     headers: {
//       'Content-Type': payload.file.name.split(".")[1]
//     },
//     onUploadProgress: onUploadProgress
//   };

//   return axios.put(payload.data.signedRequest, file, options).catch((e: any) => {
//     console.log(e.response.data)
//     throw e.response.data;
//   })
// }

const getMedia = (data: any) => {
  return axios
    .get('/media/get', data)
    .then(data => data.data)
    .catch((e: any) => {
      throw e.response.data;
    });
};

export default { getSignedUrl, createUploaderChannel, getMedia };
