import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(3)
 },
 label: {
   background: '#FFF',
   paddingLeft: 5,
   paddingRight: 5
 }
 
}));
