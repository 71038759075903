import * as React from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import useStyles from './RegistrationConfirmationStyles';
import { ReactComponent as Image } from 'Assets/undraw_welcome_3gvl.svg';
import logo from 'Assets/logo.png';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { registrationConfirmAction } from '@Core/redux/registration/registrationSlice';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
//import { useTranslation } from 'react-i18next';
import { AppState } from '@Core/redux';
import { useLocation, useParams } from 'react-router-dom';

interface IRegistrationProps {}

const RegistrationConfirmation: React.FunctionComponent<IRegistrationProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const { code }: any = useParams();
  const dispatch = useDispatch();
  //const { t, i18n } = useTranslation();
  const { error, isLoading } = useSelector(
    (state: AppState) => state.registration,
  );

  React.useEffect(() => {
    dispatch(registrationConfirmAction({ code }));
  }, []);

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item sm={6} md={6} className={classes.imageContainer}>
          <div className={classes.centerContainer}>
            <div className={classes.container}>
              <Image className={classes.image} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.centerContainer}>
            <div className={classes.form}>
              <img src={logo} style={{ width: 300 }} alt="Logo" />
              <Typography
                variant="h5"
                component="h1"
                className={classes.header}
              >
                <Trans i18nKey="trial">
                  Start Your <b>Free 14-Day Trial</b> of Edumiko Learning
                  Managment System For Experts
                </Trans>
              </Typography>
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.header}
              >
                <Trans i18nKey="norisk">
                  No risk, full functionality, no credit card required.
                </Trans>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RegistrationConfirmation;
