import axios from 'axios';
import { IUserProfile } from './userProfileTypes';

const userProfile = () => {
  return axios.get('/users/profile').catch((e: any) => {
    throw e.response.data;
  });
};

const setUserProfile = (payload: IUserProfile) => {
  return axios.post('/users/profile', payload).catch((e: any) => {
    throw e.response.data;
  });
};

export default { userProfile, setUserProfile };
