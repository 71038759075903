import axios from 'axios';

const registration = (data: any) => {
  return axios
    .post('/auth/registration', { ...data, username: data.email })
    .catch((e: any) => {
      throw e.response.data;
    });
};

const registrationConfirmation = (code: string) => {
  return axios.post('/auth/registration/confirm', { code }).catch((e: any) => {
    throw e.response.data;
  });
};

export default { registration, registrationConfirmation };
