import {
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import * as React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../BankTransfersStyles';
import { useDispatch, useSelector } from 'react-redux';
import { IBookingFilter } from '@Core/redux/bankTransfer/bankTransferTypes';
import { setFilterStartedAction } from '@Core/redux/bankTransfer/bankTransferSlice';

interface IBookingsFiltersProps {
  filters: IBookingFilter;
}

const BookingsFilters: React.FunctionComponent<IBookingsFiltersProps> = ({ filters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleFiltersChange = (e: React.ChangeEvent<{ value: string; name: string }>) => {
    const { name, value } = e.target;
    dispatch(setFilterStartedAction({ field: name, value }));
  };

  const handleDateChange = (name: string, value: string) => {
    dispatch(setFilterStartedAction({ field: name, value }));
  };

  return (
    <Paper variant="outlined" style={{ marginTop: 10 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Numer rezerwacji</TableCell>
            <TableCell>Client / Guest</TableCell>
            <TableCell>Accommodation / Reference</TableCell>
            <TableCell>Check in</TableCell>
            <TableCell>Status rezerwacji</TableCell>
            <TableCell>TotalPrice</TableCell>
            <TableCell>Web</TableCell>
            <TableCell>Last Modify Date</TableCell>
            <TableCell>BookingDate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                id="reservation"
                variant="outlined"
                size="small"
                name="reservation"
                style={{ minWidth: 100 }}
                value={filters.reservation}
                onChange={handleFiltersChange}
                placeholder=""
              />
            </TableCell>
            <TableCell>
              <TextField
                id="client"
                variant="outlined"
                size="small"
                name="client"
                style={{ minWidth: 100 }}
                value={filters.client}
                onChange={handleFiltersChange}
                placeholder=""
              />
            </TableCell>
            <TableCell>
              <TextField
                id="AccommodationCode"
                variant="outlined"
                size="small"
                name="AccommodationCode"
                style={{ minWidth: 100 }}
                value={filters.AccommodationCode}
                onChange={handleFiltersChange}
                placeholder=""
              />
            </TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.ArrivalDateFrom}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('ArrivalDateFrom', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.ArrivalDateFrom, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    style={{ minWidth: 100 }}
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('ArrivalDateFrom', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="od"
              />
            </TableCell>
            <TableCell>
              <FormControl variant="outlined" size="small">
                <Select
                  id="status"
                  style={{ minWidth: 100 }}
                  value={filters.BookingType}
                  onChange={(e) => {
                    console.log(e.target.value);
                    handleDateChange('BookingType', e.target.value as string);
                  }}
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value="UNPAID" selected>
                    UNPAID
                  </MenuItem>
                  <MenuItem value="PAID">PAID</MenuItem>
                  <MenuItem value="CANCELLED">CANCELLED</MenuItem>
                  <MenuItem value="CANCELLED">PROPIETARIO</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell>
              <TextField
                id="total-price-from"
                variant="outlined"
                size="small"
                name="TotalPriceFrom"
                style={{ minWidth: 100 }}
                value={filters.TotalPriceFrom}
                onChange={handleFiltersChange}
                placeholder="od"
              />
            </TableCell>
            <TableCell>
              <TextField
                id="web"
                variant="outlined"
                size="small"
                name="Web"
                style={{ minWidth: 100 }}
                value={filters.Web}
                onChange={handleFiltersChange}
                placeholder=""
              />
            </TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.LastModifyDateTimeFrom}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('LastModifyDateTimeFrom', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.LastModifyDateTimeFrom, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('LastModifyDateTimeFrom', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="od"
              />
            </TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.BookingDateFrom}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('BookingDateFrom', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.LastModifyDateTimeFrom, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    style={{ minWidth: 100 }}
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('BookingDateFrom', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="od"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.ArrivalDateTo}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('ArrivalDateTo', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.ArrivalDateTo, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('ArrivalDateTo', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="do"
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <TextField
                id="total-price-to"
                variant="outlined"
                size="small"
                name="TotalPriceTo"
                value={filters.TotalPriceTo}
                onChange={handleFiltersChange}
                placeholder="do"
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.LastModifyDateTimeTo}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('LastModifyDateTimeTo', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.LastModifyDateTimeTo, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('LastModifyDateTimeTo', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="do"
              />
            </TableCell>
            <TableCell>
              <DayPickerInput
                value={filters.BookingDateTo}
                formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                format={'YYYY-MM-DD'}
                onDayChange={(selectedDay) => handleDateChange('BookingDateTo', moment(selectedDay).format('YYYY-MM-DD'))}
                dayPickerProps={{
                  selectedDays: moment(filters.LastModifyDateTimeTo, 'YYYY-MM-DD').toDate(),
                }}
                component={(props: any) => (
                  <OutlinedInput
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    {...props}
                    className={classes.root}
                    classes={{ input: classes.input }}
                    autoComplete="off"
                    endAdornment={
                      <InputAdornment position="end" className={classes.adorned}>
                        <IconButton
                          aria-label="clear date"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDateChange('BookingDateTo', '');
                          }}
                          edge="end"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
                placeholder="do"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default BookingsFilters;
