import * as React from 'react';
import Header from 'Layout/Header';
import MainMenu from 'Layout/MainMenu';
import Container from 'Components/Container';
import Snackbar from 'Components/Snackbar';
import useStyles from './LayoutStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LayoutHooks from 'Layout/LayoutHooks';

interface ILayoutProps {
  children: React.ReactNode;
}

export const MainElementContext = React.createContext(null);

const Layout: React.FunctionComponent<ILayoutProps> = ({ children }) => {
  const classes = useStyles();
  const mainRef = React.createRef<HTMLDivElement>();
  return (
    <>
      <MainElementContext.Provider value={mainRef}>
        <div className={classes.root}>
          <CssBaseline />
          <Header />
          <MainMenu />
          <main className={classes.content} id="main" ref={mainRef}>
            <Container maxWidth="xl" className={classes.container}>
              {children}
            </Container>
          </main>
          <Snackbar />
        </div>
      </MainElementContext.Provider>
      <LayoutHooks />
    </>
  );
};

export default Layout;
