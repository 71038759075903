import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { IBankTransfer } from '../bank/bankTypes';
import { BankTransferState, IUnpaidBooking } from './bankTransferTypes';

const initialState: BankTransferState = {
  isLoading: false,
  bankTransfer: {
    id: '',
    ingId: '',
    amount: null,
    currency: '',
    title: '',
    bookDate: '',
    from: '',
    accountFrom: '',
    bookedBy: {
      name: '',
      id: '',
      email: '',
    },
    booking: [],
    paymentType: '',
    updated: '',
    config: {
      comment: '',
      deposit_status: '',
      extras: '',
      payment_method: '',
      payment_status: '',
      payments_date: '',
      reservation_status: '',
    },
  },
  unpaidBookings: [],
  filters: {
    reservation: '',
    client: '',
    Localizator: '',
    BookingCode: '',
    BookingType: 'UNPAID',
    AccommodationCode: '',
    TotalPriceFrom: null,
    TotalPriceTo: null,
    Web: null,
    LastModifyDateTimeFrom: null,
    LastModifyDateTimeTo: null,
    BookingDateFrom: '',
    BookingDateTo: '',
    offset: 0,
    limit: 10,
    count: 100,
    pageSize: 10,
  },
};

const bankTransferSlice = createSlice({
  name: 'BANK_TRANSFER',
  initialState,
  reducers: {
    getBankTransferStartedAction(state: Draft<BankTransferState>, action: PayloadAction<{ id: string }>) {
      state.isLoading = true;
    },
    getBankTransferFailedAction(state: Draft<BankTransferState>, action: PayloadAction<{ error: any }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    getBankTransferDoneAction(state: Draft<BankTransferState>, action: PayloadAction<{ bankTransfer: IBankTransfer }>) {
      state.isLoading = false;
      state.bankTransfer = action.payload.bankTransfer;
    },
    addBookingAction(state: Draft<BankTransferState>, action: PayloadAction<{ booking: any }>) {
      state.bankTransfer.booking.push(action.payload.booking);
    },
    removeBookingAction(state: Draft<BankTransferState>, action: PayloadAction<{ id: number }>) {
      state.bankTransfer.booking = state.bankTransfer.booking.filter((booking: any) => booking.id !== action.payload.id);
    },
    saveBankTransferStartedAction(state: Draft<BankTransferState>) {
      state.isLoading = true;
    },
    saveBankTransferDoneAction(state: Draft<BankTransferState>) {
      state.isLoading = false;
    },
    saveBankTransferFailedAction(state: Draft<BankTransferState>) {
      state.isLoading = false;
    },
    getUnpaidBookingsStartedAction(state: Draft<BankTransferState>) {
      state.isLoading = true;
    },
    getUnpaidBookingsFailedAction(state: Draft<BankTransferState>, action: PayloadAction<{ error: any }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    getUnpaidBookingsDoneAction(state: Draft<BankTransferState>, action: PayloadAction<any>) {
      state.isLoading = false;
      state.unpaidBookings = action.payload[0];
      state.filters.count = action.payload[1];
    },
    setFilterStartedAction(state: Draft<BankTransferState>, action: PayloadAction<{ field: string; value: string | number }>) {
      const { field, value } = action.payload;
      (state.filters as any)[field] = value;
    },
    setFilterDoneAction(state: Draft<BankTransferState>) {
      state.isLoading = false;
    },
    setFilterFailedAction(state: Draft<BankTransferState>) {
      state.isLoading = false;
    },
    setBankTransferField(state: Draft<BankTransferState>, action: PayloadAction<{ field: string; value: string }>) {
      const { field, value } = action.payload;
      state.bankTransfer[field] = value;
    },
  },
});

export const {
  getBankTransferStartedAction,
  getBankTransferFailedAction,
  getBankTransferDoneAction,
  addBookingAction,
  removeBookingAction,
  saveBankTransferStartedAction,
  saveBankTransferDoneAction,
  saveBankTransferFailedAction,
  getUnpaidBookingsStartedAction,
  getUnpaidBookingsFailedAction,
  getUnpaidBookingsDoneAction,
  setFilterStartedAction,
  setFilterDoneAction,
  setFilterFailedAction,
  setBankTransferField,
} = bankTransferSlice.actions;

export default bankTransferSlice.reducer;
