import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppState } from '@Core/redux';
import {
  userProfileAction,
  setUserProfileAction,
  setEditableAction,
} from '@Core/redux/userProfile/userProfileSlice';
import Title from 'Components/Title';
import Grid from '@material-ui/core/Grid';
import { Button, Typography, FormHelperText } from '@material-ui/core';
import InputSmall from 'Components/InputSmall';
import useStyles from './UserProfileStyles';
import ChangePassword from '../ChangePassword';

interface IUserProfileProps {}

const UserProfile: React.FunctionComponent<IUserProfileProps> = props => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(userProfileAction());
  }, []);

  const { user, error, isLoading, editable } = useSelector(
    (state: AppState) => state.userProfile,
  );

  const css = useStyles();

  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const UserProfileSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    name: Yup.string(),
    password: Yup.string(),
  });

  const initialValues: { name: string; email: string } = {
    email: user.email,
    name: user.name,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserProfileSchema,
    onSubmit: (values: { name: string; email: string }) => {
      dispatch(setUserProfileAction(values));
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Title
          variant="h5"
          operations={
            <>
              {!editable && (
                <Button
                  size="small"
                  onClick={() => dispatch(setEditableAction(true))}
                >
                  Edit
                </Button>
              )}
              {editable && (
                <>
                  <Button
                    size="small"
                    onClick={() => dispatch(setEditableAction(false))}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </>
              )}
            </>
          }
        >
          Profile
        </Title>

        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} className={css.grid}>
            <Typography variant="subtitle2">Email</Typography>
          </Grid>
          <Grid item xs={3} className={css.grid}>
            {!editable && (
              <Typography variant="body2"> {user.email}</Typography>
            )}
            {editable && (
              <>
                <InputSmall
                  id="email"
                  value={formik.values.email}
                  error={!!formik.errors.email && !!formik.touched.email}
                  onChange={formik.handleChange}
                />
                {!!formik.errors.email && !!formik.touched.email && (
                  <FormHelperText error={true}>
                    {formik.errors.email}
                  </FormHelperText>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} className={css.grid}>
            <Typography variant="subtitle2">Name</Typography>
          </Grid>
          <Grid item xs={3} className={css.grid}>
            {!editable && <Typography variant="body2"> {user.name}</Typography>}
            {editable && (
              <InputSmall
                id="name"
                value={formik.values.name}
                error={!!formik.errors.name && !!formik.touched.name}
                onChange={formik.handleChange}
              />
            )}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={3} className={css.grid}>
            <Typography variant="subtitle2">Password</Typography>
          </Grid>
          <Grid item xs={3} className={css.grid}>
            {!editable && <Typography variant="body2">••••••••</Typography>}

            {editable && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setOpenChangePassword(true)}
              >
                Change password
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
      <ChangePassword
        open={openChangePassword}
        handleClose={() => setOpenChangePassword(false)}
      />
    </>
  );
};

export default UserProfile;
