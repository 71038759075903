import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { MediaState, IFile } from './mediaTypes';

const initialState: MediaState = {
  isLoading: false,
  isError: false,
  files: [],
  newFile: {
    isUploaded: false,
    isLoading: false,
    progress: 0,
  },
};

const mediaSlice = createSlice({
  name: 'MEDIA',
  initialState,
  reducers: {
    getMediaAction(state: Draft<MediaState>, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getMediaActionDone(state: Draft<MediaState>, action: PayloadAction<any>) {
      console.log(action.payload);
      state.files = action.payload;
      state.isLoading = false;
    },
    getMediaActionFailed(state: Draft<MediaState>, action: PayloadAction<any>) {
      state.isLoading = false;
    },
    mediaUploadAction(
      state: Draft<MediaState>,
      action: PayloadAction<{
        file: File;
      }>,
    ) {
      state.isLoading = true;
      const file: IFile = {
        file: action.payload.file,
        isLoading: true,
        progress: 0,
        isUploaded: false,
        isConverted: false,
      };
      state.newFile = file;
    },
    uploadProgressAction(
      state: Draft<MediaState>,
      action: PayloadAction<{ progress: number; index: number }>,
    ) {
      const { progress } = action.payload;
      state.newFile.isLoading = true;
      state.newFile.progress = progress;
    },
    uploadProgressFailedAction(
      state: Draft<MediaState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
    },
    mediaUploadFinishAction(state: Draft<MediaState>) {
      state.newFile.isUploaded = true;
      state.newFile.isLoading = false;
      state.newFile.progress = 0;
    },
  },
});

export const {
  getMediaAction,
  getMediaActionDone,
  getMediaActionFailed,
  mediaUploadAction,
  uploadProgressAction,
  uploadProgressFailedAction,
  mediaUploadFinishAction,
} = mediaSlice.actions;
export { initialState };
export default mediaSlice.reducer;
