import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  FormHelperText,
  CircularProgress,
  Paper,
  Link,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import useStyles from './LoginStyles';
import { AppState } from '@Core/redux';
import { loginAction } from '@Core/redux/auth/authSlice';
import { ReactComponent as Logo } from '../../assets/logo.svg';

interface ILoginProps {}

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((state: AppState) => state.auth);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
      remember: false,
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      dispatch(loginAction(values));
    },
  });

  return (
    <Container maxWidth={false} className={classes.container}>
      <Container maxWidth="lg">
        <Grid container justify="center">
          <Grid item xs={12} sm={5} md={5}>
            <div className={classes.centerContainer}>
              <Paper>
                <div className={classes.form}>
                  <Logo style={{ width: 200 }} />
                  {/* <img src={logo} style={{ width: 300 }} alt="Logo" /> */}

                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      id="email"
                      label={`Email Address`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={!!formik.errors.email && formik.touched.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      id="password"
                      label={`Password`}
                      variant="outlined"
                      type="password"
                      fullWidth
                      margin="normal"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      error={!!formik.errors.password && formik.touched.password}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.remember}
                          onChange={() => formik.setFieldValue('remember', !formik.values.remember)}
                          name="remember"
                          color="primary"
                        />
                      }
                      className={classes.remember}
                      label="Remember Me"
                    />
                    <Button variant="contained" color="primary" disabled={isLoading} size="large" fullWidth className={classes.button} type="submit">
                      <Trans i18nKey="sign-in">Sign In</Trans>
                      {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                    <FormHelperText error={true}>{error && error.message}</FormHelperText>
                    {/* <Typography variant="subtitle2" component="p" className={classes.forgotPassword}>
                      <Trans i18nKey="forgot-password">
                        <Link href="/forgot-password" color="textSecondary">
                          Forgot password?
                        </Link>
                      </Trans>
                    </Typography> */}
                  </form>
                </div>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Login;
