import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  getUsersAction,
  getUsersActionDone,
  getUsersActionFailed,
  setSortUsersAction,
  setOffsetUsersAction,
  setOffsetUsersActionDone,
  getSearchUsersAction,
} from './usersSlice';
import Api from './usersApi';
import { AppState } from '@Core/redux';

const getUsers = (state: AppState) => state.users.params;

function* getUsersSaga(action?: any): SagaIterator {
  const params = yield select(getUsers);
  try {
    const data = yield call(Api.getUsers, params);
    if (params.offset === 0) {
      yield put(getUsersActionDone(data.data));
    } else {
      yield put(setOffsetUsersActionDone(data.data));
    }
  } catch (e) {
    yield put(getUsersActionFailed(e));
  }
}

function* sortUsers(data: any): SagaIterator {
  yield call(getUsersSaga, data);
}

function* getSearchUsersSaga(data: any): SagaIterator {
  yield call(getUsersSaga, data);
}

export default function* saga() {
  yield takeLatest(getUsersAction, getUsersSaga);
  yield takeLatest(setSortUsersAction, sortUsers);
  yield takeLatest(setOffsetUsersAction, getUsersSaga);
  yield takeLatest(getSearchUsersAction, getSearchUsersSaga);
}
