import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    background: '#f9f9f9',
  },
  center: {
    height: '100vh',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
  },
  form: {
    margin: theme.spacing(2, 10, 5, 10),
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  forgotPassword: {
    marginTop: theme.spacing(4),
  },
  remember: {
    width: '100%',
  },
}));
