import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  registrationAction,
  registrationActionDone,
  registrationActionFailed,
  registrationConfirmAction,
  registrationConfirmActionDone,
  registrationConfirmActionFailed,
} from './registrationSlice';
import { userAuthActionDone } from '@Core/redux/auth/authSlice';
import Api from './registrationApi';
import history from '@Core/history';

function* registrationSaga({ payload }: any): SagaIterator {
  try {
    const data = yield call(Api.registration, payload.values);
    yield put(registrationActionDone(data.data));
    yield put(userAuthActionDone(data.data));
    yield call(() => history.push('/dashboard'));
  } catch (e) {
    yield put(registrationActionFailed(e));
  }
}

function* registrationConfirmationSaga({ payload }: any): SagaIterator {
  try {
    console.log(payload);
    const data = yield call(Api.registrationConfirmation, payload.code);
    yield put(registrationConfirmActionDone(data.data));
    yield put(userAuthActionDone(data.data));
  } catch (e) {
    yield put(registrationConfirmActionFailed(e));
  }
}

export default function* authSaga() {
  yield takeLatest(registrationAction, registrationSaga);
  yield takeLatest(registrationConfirmAction, registrationConfirmationSaga);
}
