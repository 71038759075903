import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@Core/redux';
import MaterialTable from 'material-table';
import Layout from 'Layout/Layout';
import {
  getUsersAction,
  setSortUsersAction,
  setOffsetUsersAction,
} from '@Core/redux/usersManagement/users/usersSlice';
import { ISort } from '@Core/redux/IParams';
import { useDimensions } from './useDimensions';
interface IUsersProps {}

const Users: React.FunctionComponent<IUsersProps> = () => {
  const dispatch = useDispatch();

  const getData = (offset: number) => {
    dispatch(setOffsetUsersAction(offset));
    //dispatch(getUsersAction());
  };

  const sortHandler = (sort: ISort) => {
    dispatch(setSortUsersAction(sort));
  };

  React.useEffect(() => {
    dispatch(getUsersAction());
  }, []);

  const { users, isLoading, params } = useSelector(
    (state: AppState) => state.users,
  );

  return (
    <Layout>
      <div style={{ maxWidth: '100%', height: 'calc(100vh - 300px)' }}>
        {
          <MaterialTable
            columns={[
              { title: 'username', field: 'username' },
              { title: 'name', field: 'name' },
              { title: 'email', field: 'email' },
              {
                title: 'created',
                field: 'created',
              },
              {
                title: 'isActive',
                field: 'isActive',
              },
              {
                title: 'isConfirmed',
                field: 'isConfirmed',
              },
            ]}
            page={params.offset / params.limit}
            options={{ pageSize: 5 }}
            totalCount={56}
            data={users.map(o => ({ ...o }))}
            onChangePage={(page: number, pageSize: number) => {
              const offset = page * pageSize;
              dispatch(setOffsetUsersAction(offset));
              console.log(page);
              console.log(pageSize);
            }}
            title="Users"
          />
        }
      </div>
    </Layout>
  );
};

export default Users;
