import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Registration from 'Views/Registration';
import RegistrationConfirmation from 'Views/Registration/Confirmation';
import Login from 'Views/Login';
import Dashboard from 'Views/Dashboard';
import Profile from 'Views/Profile';
import Users from 'Views/UsersManagement/Users';
import AuthRoute from 'Components/AuthRoute';
import history from '@Core/history';
import Payments from 'Views/Settings/Payments';
import BankTransfers from 'Views/BankTransfers';
import Bookings from 'Views/Bookings';
import BankTransferEdit from './BankTransfers/BankTransferEdit';

interface IMainRouterProps {}

const MainRouter: React.FunctionComponent<IMainRouterProps> = (props) => {
  return (
    // @ts-ignore
    <Router history={history}>
      <Switch>
        <AuthRoute path="/bank-transfers/:id">
          <BankTransferEdit />
        </AuthRoute>
        <AuthRoute path="/bank-transfers">
          <BankTransfers />
        </AuthRoute>
        <AuthRoute path="/bookings">
          <Bookings />
        </AuthRoute>
        <Route path="/registration/confirm/:code">
          <RegistrationConfirmation />
        </Route>
        <Route path="/registration">
          <Registration />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <AuthRoute path="/dashboard">
          <Dashboard />
        </AuthRoute>
        <AuthRoute path="/profile">
          <Profile />
        </AuthRoute>
        <AuthRoute path="/users-management/roles">
          <Profile />
        </AuthRoute>
        <AuthRoute path="/users-management/users">
          <Users />
        </AuthRoute>
        <Route path="/" exact>
          <Login />
        </Route>
      </Switch>
    </Router>
  );
};

export default MainRouter;
