import { all, call } from 'redux-saga/effects';
import authSaga from './auth/authSaga';
import registrationSaga from './registration/registrationSaga';
import userProfileSaga from './userProfile/userProfileSaga';
import usersSaga from './usersManagement/users/usersSaga';
import mediaSaga from './media/mediaSaga';
import payments from './ecommerce/payments/paymentsSaga';
import bank from './bank/bankSaga';
import bankTransfer from './bankTransfer/bankTransferSaga';

export default function* rootSaga() {
  yield all([authSaga(), registrationSaga(), userProfileSaga(), usersSaga(), mediaSaga(), payments(), bank(), bankTransfer()]);
}
