import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  getProductsAction,
  getProductsActionDone,
  getProductsActionFailed,
  getSubscriptionsAction,
  getSubscriptionsActionDone,
  getSubscriptionsActionFailed,
  createSubscriptionsAction,
  createSubscriptionsActionDone,
  createSubscriptionsActionFailed,
} from './paymentsSlice';
import Api from './paymentsApi';

function* getProductsSaga(): SagaIterator {
  try {
    const data = yield call(Api.getProducts);
    yield put(getProductsActionDone({ products: data.data }));
  } catch (e) {
    yield put(getProductsActionFailed(e));
  }
}

function* getSubscriptionsSaga(): SagaIterator {
  try {
    const data = yield call(Api.getSubscriptions);
    yield put(getSubscriptionsActionDone({ subscriptions: data.data }));
  } catch (e) {
    yield put(getSubscriptionsActionFailed(e));
  }
}

function* createSubscriptionsSaga(): SagaIterator {
  try {
    const data = yield call(Api.createSubscription);
    yield put(createSubscriptionsActionDone({ subscriptions: data.data }));
  } catch (e) {
    yield put(createSubscriptionsActionFailed(e));
  }
}

export default function* paymentsSaga() {
  yield takeLatest(getProductsAction, getProductsSaga);
  yield takeLatest(getSubscriptionsAction, getSubscriptionsSaga);
  yield takeLatest(createSubscriptionsAction, createSubscriptionsSaga);
}
