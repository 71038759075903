import * as React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Layout from 'Layout/Layout';
import { Breadcrumbs, Link, Typography, Paper, Grid, IconButton, Chip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBankTransfersStartedAction } from '@Core/redux/bank/bankSlice';
import { AppState } from '@Core/redux';
import { bankTransferSelector } from '@Core/redux/bank/bankSelector';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import BankTransfersFilter from './BankTransfersFilter';
import { BankTransferStatus, PaymentType } from '@Core/redux/bank/bankTypes';

interface IBankTransfersProps {}

const BankTransfers: React.FunctionComponent<IBankTransfersProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getBankTransfersStartedAction());
  }, []);

  const { isLoading, error, bankTransfers, bankTranserFilters } = useSelector(bankTransferSelector);

  function getPaymentTypeLabel(value: PaymentType) {
    return {
      p24: 'P24',
      exclusive: 'EXCLUSIVE',
      edomizil: 'E-DOMIZIL',
    }[value];
  }
  function getStatusLabel(value: BankTransferStatus) {
    return {
      booked: 'ZAKSIĘGOWANY W AVANTIO',
      new: 'NOWY',
      matched: 'REZERWACJA DODANA',
      disabled_booking: 'NIE KSIĘGOWAĆ',
      not_located: 'REZERWACJA NIEZLOKALIZOWANA',
      nota_obciazeniowa: 'NOTA OBCIĄŻENIOWA',
      transfer_not_located: 'PRZELEW NIEZLOKALIZOWANY',
    }[value];
  }

  return (
    <Layout>
      <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 18 }}>
        <Link color="inherit" href="/dashboard" onClick={() => {}}>
          Panel zarządzania
        </Link>
        <Typography color="textPrimary">Przelewy</Typography>
      </Breadcrumbs>
      <Paper variant="outlined">
        <BankTransfersFilter filters={bankTranserFilters} />
        <Grid container spacing={3} style={{ padding: 12 }}>
          <Grid item sm={12}>
            <MaterialTable
              style={{ width: '100%' }}
              options={{
                paging: false,
                headerStyle: {
                  zIndex: 0,
                },
                tableLayout: 'auto',
                draggable: false,

                search: false,
                showTitle: false,
                toolbar: false,
              }}
              columns={[
                {
                  title: 'Edycja',
                  field: 'edit',
                  sorting: false,
                  headerStyle: { textAlign: 'center', width: 20, maxWidth: 20 },

                  render: ({ id }) => (
                    <RouterLink to={`/bank-transfers/${id}`}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </RouterLink>
                  ),
                },
                {
                  title: 'Data księgowania',
                  field: 'bookDate',
                  headerStyle: {
                    textAlign: 'center',
                    width: 30,
                    maxWidth: 30,
                  },

                  render: ({ bookDate }) => <span>{moment(bookDate).format('YYYY-MM-DD')}</span>,
                },
                {
                  title: 'Kwota',
                  field: 'amount',
                  headerStyle: { textAlign: 'center', width: 20, maxWidth: 20 },
                },
                {
                  title: 'Waluta',
                  field: 'currency',
                  headerStyle: { textAlign: 'center', width: 20, maxWidth: 20 },
                  width: '5%',
                },
                {
                  title: 'Kontrahent',
                  field: 'from',
                  headerStyle: { width: 250, maxWidth: 250 },
                  width: '10%',
                },
                {
                  title: 'Tytuł operacji',
                  field: 'title',
                  headerStyle: { width: 250, maxWidth: 250 },
                  width: '10%',
                },
                {
                  title: 'Rodzaj płatności',
                  field: 'paymentType',
                  headerStyle: { textAlign: 'center' },
                  cellStyle: { textAlign: 'center' },
                  width: '5%',
                  render: ({ paymentType }) =>
                    paymentType && <Chip variant="default" size="small" label={getPaymentTypeLabel(paymentType as PaymentType)} />,
                },
                {
                  title: 'Numer rezerwacji',
                  field: 'booking',
                  headerStyle: { textAlign: 'center' },
                  cellStyle: { textAlign: 'center' },
                  width: '10%',
                  render: ({ booking }) => (
                    <span>
                      {booking.map((book: any) => (
                        <Chip key={book.id} label={book.Localizator} variant="outlined" size="small" style={{ margin: '5px 0 5px 0' }} />
                      ))}
                    </span>
                  ),
                },
                {
                  title: 'Kto zaksiegował',
                  field: 'bookedBy',
                  headerStyle: { textAlign: 'center' },
                  width: '5%',
                  render: ({ bookedBy }) => <span>{bookedBy?.name}</span>,
                },
                {
                  title: 'Typ',
                  field: 'type',
                  headerStyle: { textAlign: 'center' },
                  cellStyle: { textAlign: 'center' },
                  render: ({ type }) => <Chip variant="default" size="small" label={type} />,
                },
                {
                  title: 'Status',
                  field: 'status',
                  render: ({ status }) => <Chip variant="default" size="small" label={getStatusLabel(status as BankTransferStatus)} />,
                  headerStyle: { textAlign: 'center' },
                  cellStyle: { textAlign: 'center' },
                },
              ]}
              data={bankTransfers.map((o) => ({ ...o }))}
            />
            <Paper variant="outlined"></Paper>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};

export default BankTransfers;
