import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  center: {
    height: '100vh',
  },
  header: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  subheader: {
    textAlign: 'center',
  },
  centerContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
  },
  container: {
    margin: theme.spacing(8, 8),
  },
  button: {
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  imageContainer: {
    padding: theme.spacing(0, 8),
  },
  image: {
    maxWidth: '100%',
    height: 500,
  },
  form: {
    margin: theme.spacing(8, 10),
    textAlign: 'center',
  },
}));
