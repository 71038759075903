import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '100px',
  },
  input: {
    padding: '10px 0px 10px 14px',
  },
  adorned: {
    marginLeft: 0,
  },
}));

export { useStyles };
