import * as React from 'react';
import { Typography, Divider, TypographyProps } from '@material-ui/core';
import useStyles from './TitleStyles';
interface ITitleProps extends TypographyProps {
  operations?: JSX.Element;
}

const Title: React.FunctionComponent<ITitleProps> = ({
  children,
  variant,
  operations,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.title}>
      <Typography variant={variant} gutterBottom={true}>
        {children}
      </Typography>
      <div className={classes.operation}>{operations}</div>
      <Divider variant="fullWidth" className={classes.divider} />
    </div>
  );
};

export default Title;
