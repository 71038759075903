import * as React from 'react';
import Layout from 'Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@Core/redux';
import { userProfileAction } from '@Core/redux/userProfile/userProfileSlice';
import Title from 'Components/Title';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@material-ui/core';
import UserProfile from './UserProfile';

interface IProfileProps {}

const Profile: React.FunctionComponent<IProfileProps> = props => {
  // const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(userProfileAction());
  // }, []);

  return (
    <Layout>
      <UserProfile />
    </Layout>
  );
};

export default Profile;
