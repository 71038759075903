import * as React from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '@Core/redux';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';

interface ISnackbarProps {}

const ESnackbar: React.FunctionComponent<ISnackbarProps> = (props) => {
  const dispatch = useDispatch();

  const { isOpen, message, severity, autoHideDuration } = useSelector((state: AppState) => state.snackbar);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={() => dispatch(snackbarAction({ isOpen: false }))}
    >
      <Alert severity={severity} onClose={() => dispatch(snackbarAction({ isOpen: false }))}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ESnackbar;
