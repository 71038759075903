import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { UserAuthState } from './authTypes';
import auth from '@Core/auth';

const initialState: UserAuthState = {
  isLoading: false,
  isLogged: auth(),
  isAuthChecked: false,
  access_token: '',
  member: null,
  owner: null,
  error: {},
};

const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    userAuthAction(state: Draft<UserAuthState>) {
      state.isLoading = true;
    },
    userAuthActionDone(
      state: Draft<UserAuthState>,
      action: PayloadAction<any>,
    ) {
      const { payload } = action;
      state.owner = payload.organization.owner;
      state.member = payload.organization.member;
      state.isAuthChecked = true;
      state.isLoading = false;
    },
    userAuthActionFailed(
      state: Draft<UserAuthState>,
      action: PayloadAction<UserAuthState>,
    ) {
      state.isLoading = false;
    },
    loginAction(
      state: Draft<UserAuthState>,
      payload: PayloadAction<{ password: string; email: string }>,
    ) {
      state.isLoading = true;
      state.error = {};
    },
    loginActionDone(
      state: Draft<UserAuthState>,
      action: PayloadAction<UserAuthState>,
    ) {
      console.log(action.payload)
      state.isLoading = false;
      state.owner = action.payload.owner;
      state.member = action.payload.member;
      state.error = {};
    },
    loginActionFailed(
      state: Draft<UserAuthState>,
      action: PayloadAction<UserAuthState>,
    ) {
      state.isLoading = false;
      state.error = action.payload;
      console.log(action.payload);
    },
    logoutAction(
      state: Draft<UserAuthState>,
      payload: PayloadAction<{ password: string; email: string }>,
    ) {
      state.isLoading = true;
      state.error = {};
    },
    logoutActionDone(
      state: Draft<UserAuthState>,
      action: PayloadAction<UserAuthState>,
    ) {
      console.log(action.payload)
      state.isLoading = false;
      state.error = {};
    },
    logoutActionFailed(
      state: Draft<UserAuthState>,
      action: PayloadAction<UserAuthState>,
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setOrganizationOwnerAction(
      state: Draft<UserAuthState>,
      action: PayloadAction<{name: string}>,
    ) {
      state.isLoading = true;
    },
    setOrganizationOwnerActionDone(
      state: Draft<UserAuthState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.owner = action.payload.owner
    },
    setOrganizationOwnerActionFailed(
      state: Draft<UserAuthState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
    }
  },
});

export const {
  userAuthAction,
  userAuthActionDone,
  userAuthActionFailed,
  loginAction,
  loginActionDone,
  loginActionFailed,
  logoutAction,
  logoutActionDone,
  logoutActionFailed,
  setOrganizationOwnerAction,
  setOrganizationOwnerActionDone,
  setOrganizationOwnerActionFailed,
} = authSlice.actions;
export { initialState };
export default authSlice.reducer;
