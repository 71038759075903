import Cookies from 'js-cookie';

const auth = () => {
  return Cookies.get().isLogged === 'true';
};

export const logout = (history: any) => {
  history.push('/login');
};

export default auth;
