import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { SnackbarState } from './snackbarTypes';

const initialState: SnackbarState = {
  isLoading: false,
  isOpen: false,
  message: '',
  severity: 'success',
  autoHideDuration: 5000,
};

const snackbarSlice = createSlice({
  name: 'SNACKBAR',
  initialState,
  reducers: {
    snackbarAction(state: Draft<SnackbarState>, action: PayloadAction<any>) {
      state.isOpen = action.payload.isOpen;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
  },
});

export const { snackbarAction } = snackbarSlice.actions;
export { initialState };
export default snackbarSlice.reducer;
