import axios from 'axios';
import { IBankTransfer } from '../bank/bankTypes';

export const getBankTransfer = (id: string) => {
  return axios.get(`/bank/bank-transfers/${id}`).catch((e: any) => {
    throw e.response.data;
  });
};

export const saveBankTransfer = (bankTransfer: IBankTransfer) => {
  return axios
    .put(`/bank/bank-transfers/${bankTransfer.id}`, {
      ...bankTransfer,
    })
    .catch((e: any) => {
      throw e.response.data;
    });
};

export const getUnpaidBookings = (params?: any) => {
  return axios
    .get('/bank/unpaid-bookings', {
      params,
    })
    .catch((e: any) => {
      throw e.response.data;
    });
};
