import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { BankState, IBankTransfer, IBankTransferFilter, PaymentType, BankTransferStatus, ICurrency } from './bankTypes';

const initialState: BankState = {
  isLoading: false,
  bankTransfers: [],
  bankTranserFilters: {
    title: '',
    from: '',
    bookDateFrom: '',
    bookDateTo: '',
    amount_from: '',
    amount_to: '',
    paymentType: [],
    avantioBookingId: '',
    bookedBy: '',
    status: [BankTransferStatus.new, BankTransferStatus.matched, BankTransferStatus.not_located],
    currency: [ICurrency.PLN, ICurrency.EUR],
  },

  error: {},
};

const bankSlice = createSlice({
  name: 'BANK',
  initialState,
  reducers: {
    getBankTransfersStartedAction(state: Draft<BankState>) {
      state.isLoading = true;
    },
    getBankTransfersFailedAction(state: Draft<BankState>, action: PayloadAction<{ error: any }>) {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    getBankTransfersDoneAction(state: Draft<BankState>, action: PayloadAction<{ bankTransfers: IBankTransfer[]; count: number }>) {
      state.isLoading = false;
      state.bankTransfers = action.payload.bankTransfers;
    },

    setFilterStartedAction(state: Draft<BankState>, action: PayloadAction<{ field: string; value: string | string[] }>) {
      const { field, value } = action.payload;
      (state.bankTranserFilters as any)[field] = value;
    },
  },
});

export const { getBankTransfersStartedAction, getBankTransfersFailedAction, getBankTransfersDoneAction, setFilterStartedAction } = bankSlice.actions;

export default bankSlice.reducer;
