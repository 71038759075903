import * as React from 'react';
import Layout from 'Layout/Layout';
import { Breadcrumbs, Link, Typography, Paper, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUnpaidBookingsStartedAction } from '@Core/redux/bankTransfer/bankTransferSlice';
import { AppState } from '@Core/redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MaterialTable from 'material-table';

interface IBookingsProps {}

const Bookings: React.FunctionComponent<IBookingsProps> = ({}) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUnpaidBookingsStartedAction());
  }, []);
  const { isLoading, error, unpaidBookings } = useSelector((state: AppState) => state.bankTransfer);

  return (
    <Layout>
      <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 18 }}>
        <Link color="inherit" href="/dashboard" onClick={() => {}}>
          Panel zarządzania
        </Link>
        <Typography color="textPrimary">Rezerwacje</Typography>
      </Breadcrumbs>
      <Paper variant="outlined">
        <Grid container spacing={3} style={{ padding: 18 }}>
          <Grid item sm={8}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TableContainer component={Paper} variant="outlined">
                  <Table aria-label="Nieopłacone rezerwcje">
                    <TableHead>
                      <TableRow>
                        <TableCell>Localizator</TableCell>
                        <TableCell>BookingCode</TableCell>
                        <TableCell align="right">TotalPrice</TableCell>
                        <TableCell align="right">Web</TableCell>
                        <TableCell align="right">LastModifyDateTime</TableCell>
                        <TableCell align="right">BookingPayments</TableCell>
                        <TableCell align="right">BookingDate</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {unpaidBookings.map((booking) => (
                        <TableRow key={booking.id}>
                          <TableCell component="th" scope="row">
                            {booking.Localizator}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {booking.BookingCode}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {booking.TotalPrice} {booking.Currency}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {booking.Web}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {booking.LastModifyDateTime}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            BookingPayments
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {booking.BookingDate}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};

export default Bookings;
