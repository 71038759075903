import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  userProfileAction,
  userProfileActionDone,
  userProfileActionFailed,
  setUserProfileAction,
  setUserProfileActionDone,
  setUserProfileActionFailed,
  setEditableAction,
} from './userProfileSlice';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';
import Api from './userProfileApi';
import { IUserProfile } from './userProfileTypes';

function* userProfileSaga(): SagaIterator {
  try {
    const data = yield call(Api.userProfile);
    yield put(userProfileActionDone(data.data));
  } catch (e) {
    yield put(userProfileActionFailed(e));
  }
}

function* setUserProfileSaga({ payload }: any): SagaIterator {
  try {
    const data = yield call(Api.setUserProfile, payload);
    yield put(setUserProfileActionDone(data.data));
    yield put(setEditableAction(false));
  } catch (e) {
    yield put(setUserProfileActionFailed(e));
    yield put(
      snackbarAction({ severity: 'error', message: e.message, isOpen: true }),
    );
  }
}

export default function* authSaga() {
  yield takeLatest(userProfileAction, userProfileSaga);
  yield takeLatest(setUserProfileAction, setUserProfileSaga);
}
