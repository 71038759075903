import axios from 'axios';

const getProducts = () => {
  return axios.get('/ecommerce/products/').catch((e: any) => {
    throw e.response.data;
  });
};

const getSubscriptions = () => {
  return axios.get('/ecommerce/subscriptions/').catch((e: any) => {
    throw e.response.data;
  });
};

const createSubscription = () => {
  return axios.post('/ecommerce/create-subscription/').catch((e: any) => {
    throw e.response.data;
  });
};

export default { getProducts, getSubscriptions, createSubscription };
