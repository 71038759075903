import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    position: 'relative',
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  operation: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));
