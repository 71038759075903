import * as React from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import useStyles from './RegistrationStyles';
import { ReactComponent as Image } from 'Assets/undraw_welcome_3gvl.svg';
import logo from 'Assets/logo.png';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { registrationAction } from '@Core/redux/registration/registrationSlice';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';
//import { useTranslation } from 'react-i18next';
import { AppState } from '@Core/redux';

interface IRegistrationProps {}

const Registration: React.FunctionComponent<IRegistrationProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  //const { t, i18n } = useTranslation();
  const { error, isLoading } = useSelector(
    (state: AppState) => state.registration,
  );

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .required('Required')
      .min(6, 'At least 6 characters'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: values => {
      dispatch(registrationAction({ values, history }));
    },
  });

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item sm={6} md={6} className={classes.imageContainer}>
          <div className={classes.centerContainer}>
            <div className={classes.container}>
              <Image className={classes.image} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <div className={classes.centerContainer}>
            <div className={classes.form}>
              <img src={logo} style={{ width: 300 }} alt="Logo" />
              <Typography
                variant="h5"
                component="h1"
                className={classes.header}
              >
                <Trans i18nKey="trial">
                  Start Your <b>Free 14-Day Trial</b> of Edumiko Learning
                  Managment System For Experts
                </Trans>
              </Typography>
              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.header}
              >
                <Trans i18nKey="norisk">
                  No risk, full functionality, no credit card required.
                </Trans>
              </Typography>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id="name"
                  label={`Full Name`}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={!!formik.errors.name && formik.touched.name}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  id="email"
                  label={`Email Address`}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={!!formik.errors.email && formik.touched.email}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete="email"
                />
                <TextField
                  id="password"
                  label={`Password`}
                  variant="outlined"
                  type="password"
                  fullWidth
                  margin="normal"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={!!formik.errors.password && formik.touched.password}
                  helperText={formik.touched.password && formik.errors.password}
                  autoComplete="new-password"
                />
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  size="large"
                  fullWidth
                  className={classes.button}
                  type="submit"
                >
                  <Trans i18nKey="signup">Sign Up Now</Trans>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
                <FormHelperText error={true}>
                  {error && error.message}
                </FormHelperText>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Registration;
