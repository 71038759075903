import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { UsersState, IUser } from './usersTypes';

const initialState: UsersState = {
  users: [],
  isLoading: false,
  error: {},
  params: {
    sort: {
      field: null,
      direction: 'asc',
    },
    limit: 5,
    offset: 0,
  },
};

const usersSlice = createSlice({
  name: 'USERSMANAGEMENT_USERS',
  initialState,
  reducers: {
    getUsersAction(state: Draft<UsersState>, action: PayloadAction<{ offset: number }>) {
      state.params.name = '';
      state.isLoading = true;
    },
    getUsersActionDone(state: Draft<UsersState>, action: PayloadAction<IUser[]>) {
      state.isLoading = false;
      state.users = action.payload;
    },
    getUsersActionFailed(state: Draft<UsersState>, action: PayloadAction<any>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getSearchUsersAction(state: Draft<UsersState>, action: PayloadAction<any>) {
      state.params.name = action.payload;
      state.isLoading = true;
    },
    setSortUsersAction(state: Draft<UsersState>, action: PayloadAction<any>) {
      state.params.sort = action.payload;
      state.params.offset = 0;
    },
    setOffsetUsersAction(state: Draft<UsersState>, action: PayloadAction<any>) {
      state.isLoading = true;
      state.params.offset = action.payload;
    },
    setOffsetUsersActionDone(state: Draft<UsersState>, action: PayloadAction<any>) {
      state.isLoading = false;
      state.users = [...action.payload];
    },
  },
});

export const {
  getUsersAction,
  getUsersActionDone,
  getUsersActionFailed,
  setSortUsersAction,
  setOffsetUsersAction,
  setOffsetUsersActionDone,
  getSearchUsersAction,
} = usersSlice.actions;
export { initialState };
export default usersSlice.reducer;
