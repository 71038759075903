import axios from 'axios';

const getUsers = (params: any) => {
  const {
    limit,
    offset,
    sort: { direction, field },
    name,
  } = params;
  return axios
    .get('/users/users', {
      params: {
        limit,
        offset,
        direction,
        field,
        name,
      },
    })
    .catch((e: any) => {
      throw e.response.data;
    });
};

export default { getUsers };
