import { call, put, takeLatest, select, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  getBankTransferStartedAction,
  getBankTransferFailedAction,
  getBankTransferDoneAction,
  saveBankTransferStartedAction,
  saveBankTransferDoneAction,
  saveBankTransferFailedAction,
  getUnpaidBookingsStartedAction,
  getUnpaidBookingsFailedAction,
  getUnpaidBookingsDoneAction,
  setFilterStartedAction,
  setFilterDoneAction,
  setFilterFailedAction,
} from './bankTransferSlice';
import { bankTransferSelector, filterSelector } from '@Core/redux/bankTransfer/bankTransferSelector';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';
import history from '@Core/history';

import * as Api from './bankTransferApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { publicDecrypt } from 'crypto';

function* getBankTransferSaga({ payload }: PayloadAction<{ id: string }>): SagaIterator {
  try {
    const { id } = payload;
    const { data } = yield call(Api.getBankTransfer, id);
    yield put(getBankTransferDoneAction(data));
  } catch (e) {
    yield put(saveBankTransferFailedAction());
  }
}

function* saveBankTransferSaga({ payload }: PayloadAction): SagaIterator {
  try {
    const { bankTransfer } = yield select(bankTransferSelector);
    yield call(Api.saveBankTransfer, bankTransfer);
    yield put(saveBankTransferDoneAction());
    yield put(snackbarAction({ message: 'Przyjete do zaksiegowania w avantio', isOpen: true }));
    yield call(() => history.push('/bank-transfers'));
  } catch (e) {
    yield put(snackbarAction({ severity: 'error', message: e.message, isOpen: true }));
    yield put(saveBankTransferFailedAction());
  }
}

function* getUnpaidBookingsSaga(): SagaIterator {
  try {
    const filters = yield select(filterSelector);
    const { data } = yield call(Api.getUnpaidBookings, filters);
    yield put(getUnpaidBookingsDoneAction(data));
  } catch (e) {
    yield put(getUnpaidBookingsFailedAction({ error: e }));
  }
}

function* filterBankTransferSaga({ payload }: any): SagaIterator {
  try {
    const filters = yield select(filterSelector);
    const { data } = yield call(Api.getUnpaidBookings, filters);
    yield put(getUnpaidBookingsDoneAction(data));
  } catch (e) {
    yield put(getUnpaidBookingsFailedAction({ error: e }));
  }
}

export default function* bankSaga() {
  yield takeLatest(getBankTransferStartedAction, getBankTransferSaga);
  yield takeLatest(saveBankTransferStartedAction, saveBankTransferSaga);
  yield takeLatest(getUnpaidBookingsStartedAction, getUnpaidBookingsSaga);
  yield takeLatest(setFilterStartedAction, filterBankTransferSaga);
}
