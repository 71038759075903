import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '@Core/redux';
import { userAuthAction } from '@Core/redux/auth/authSlice';
import OrganizationSettings from 'Views/Profile/OrganizationSettings';

const LayoutHooks: React.FunctionComponent<any> = props => {
  const { owner, isAuthChecked, isLogged } = useSelector(
    (state: AppState) => state.auth,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(userAuthAction());
  }, []);

  return (
    <>
      {!owner && isLogged && isAuthChecked && (
        <OrganizationSettings
          open={true}
          handleClose={() => {
            console.log('Close');
          }}
        />
      )}
    </>
  );
};

export default LayoutHooks;
