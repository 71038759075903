import { call, put, fork, takeLatest, take, cancelled, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { getMediaAction, getMediaActionDone, mediaUploadAction, uploadProgressAction, uploadProgressFailedAction } from './mediaSlice';
import { IFile } from './mediaTypes';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';
import Api from './mediaApi';
import { AppState } from '@Core/redux';

const getFiles = (state: AppState) => state.media.newFile;

function* mediaUploadSaga({
  payload,
}: PayloadAction<{
  file: File;
  lessonId: number;
  sectionId: number;
}>): SagaIterator {
  try {
    const data = yield call(Api.getSignedUrl, payload.file);
    const file = yield select(getFiles);
    const uploadChannel = yield call(Api.createUploaderChannel, {
      data,
      file: payload.file,
    });
    yield call(uploadProgressWatcher, 0, uploadChannel);
  } catch (e) {
    // yield put(userProfileActionFailed(e));
  }
}

function* uploadProgressWatcher(index: number, channel: any): any {
  while (true) {
    try {
      const progress = yield take(channel);
      console.log('progress', progress);
      yield put(uploadProgressAction({ progress, index }));
    } catch (err) {
      yield put(uploadProgressFailedAction(err));
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  }
}

function* getMediaSaga({ payload }: any): SagaIterator {
  try {
    const data = yield call(Api.getMedia, payload);
    yield put(getMediaActionDone(data));
    // yield put(setEditableAction(false));
  } catch (e) {
    // yield put(setUserProfileActionFailed(e));
    // yield put(
    //   snackbarAction({ severity: 'error', message: e.message, isOpen: true }),
    // );
  }
}

export default function* mediaSaga() {
  yield takeLatest(mediaUploadAction, mediaUploadSaga);
  yield takeLatest(getMediaAction, getMediaSaga);
  // yield takeLatest(setUserProfileAction, setUserProfileSaga);
}
