import { createSlice, PayloadAction, Draft, Slice } from '@reduxjs/toolkit';
import { PaymentsState } from './paymentsTypes';

const initialState: PaymentsState = {
  error: false,
  isLoading: true,
  products: [],
  isLoadingSubsription: true,
  subscriptions: [],
};

const paymentsSlice: Slice = createSlice({
  name: 'PAYMENTS',
  initialState,
  reducers: {
    getProductsAction(state: Draft<PaymentsState>, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    getProductsActionDone(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.products = action.payload.products;
    },
    getProductsActionFailed(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.error = true;
    },
    getSubscriptionsAction(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoadingSubsription = true;
    },
    getSubscriptionsActionDone(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoadingSubsription = false;
      state.subscriptions = action.payload.subscriptions;
    },
    getSubscriptionsActionFailed(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoadingSubsription = false;
      state.error = true;
    },
    createSubscriptionsAction(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = true;
    },
    createSubscriptionsActionDone(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.subscriptions = action.payload.subscriptions;
    },
    createSubscriptionsActionFailed(
      state: Draft<PaymentsState>,
      action: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.error = true;
    },
  },
});

export const {
  getProductsAction,
  getCoursesListAction,
  getProductsActionDone,
  getProductsActionFailed,
  getSubscriptionsAction,
  getSubscriptionsActionDone,
  getSubscriptionsActionFailed,
  createSubscriptionsAction,
  createSubscriptionsActionDone,
  createSubscriptionsActionFailed,
} = paymentsSlice.actions;
export { initialState };
export default paymentsSlice.reducer;
