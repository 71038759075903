import { combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import undoable from 'redux-undo';
import { configureStore } from '@reduxjs/toolkit';
import rootSaga from './rootSaga';
import auth from './auth/authSlice';
import registration from './registration/registrationSlice';
import userProfile from './userProfile/userProfileSlice';
import snackbar from './snackbar/snackbarSlice';
import users from './usersManagement/users/usersSlice';
import media from './media/mediaSlice';
import payments from './ecommerce/payments/paymentsSlice';
import bank from './bank/bankSlice';
import bankTransfer from './bankTransfer/bankTransferSlice';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth,
    registration,
    userProfile,
    snackbar,
    users,
    media,
    payments,
    bank,
    bankTransfer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;

export type AppState = ReturnType<typeof store.getState>;
