import axios from 'axios';
import { IBankTransferFilter } from './bankTypes';

export const getBankTransfers = (params: IBankTransferFilter | {}) => {
  return axios
    .get('/bank/bank-transfers', {
      params,
    })
    .catch((e: any) => {
      throw e.response.data;
    });
};
