import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from 'Layout/Layout';
import {
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Backdrop,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import {
  getBankTransferStartedAction,
  addBookingAction,
  removeBookingAction,
  saveBankTransferStartedAction,
  setBankTransferField,
} from '@Core/redux/bankTransfer/bankTransferSlice';
import { bankTransferSelector } from '@Core/redux/bankTransfer/bankTransferSelector';
import moment from 'moment';
import BankTrnsferEditBookings from './BankTrnsferEditBookings';
import BookingsFilters from './BookingsFilters';
import ClearIcon from '@material-ui/icons/Clear';
import LinkIcon from '@material-ui/icons/Link';

interface IBankTransferEditProps {}

const BankTransferEdit: React.FunctionComponent<IBankTransferEditProps> = ({}) => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { bankTransfer, filters, isLoading } = useSelector(bankTransferSelector);
  const [isBookingActive, setIsBookingActive] = React.useState(false);

  React.useEffect(() => {
    dispatch(getBankTransferStartedAction({ id }));
  }, []);
  React.useEffect(() => {
    bankTransfer.status === 'disabled_booking' ||
    bankTransfer.paymentType === 'debitNote' ||
    bankTransfer.paymentType === 'p24' ||
    bankTransfer.paymentType === ''
      ? setIsBookingActive(false)
      : setIsBookingActive(true);
  }, [bankTransfer]);
  const onBookingAdd = (booking: any) => {
    dispatch(addBookingAction({ booking }));
  };
  const onBankTransferSave = () => {
    dispatch(saveBankTransferStartedAction());
  };
  const onBookingRemove = (id: number) => {
    dispatch(removeBookingAction({ id }));
  };
  const handleChange = (field: string, value: string) => {
    dispatch(setBankTransferField({ field, value }));
  };
  const handleStatus = (value: string) => {
    handleChange('status', value);
    setIsBookingActive(value === 'disabled_booking' || bankTransfer.paymentType === 'debitNote' || bankTransfer.paymentType === 'p24' ? false : true);
  };
  const handlePaymentType = (value: string) => {
    handleChange('paymentType', value);
    setIsBookingActive(value === 'debitNote' || value === 'p24' || bankTransfer.status === 'disabled_booking' ? false : true);
  };

  return (
    <>
      <Layout>
        <Grid container>
          <Grid item sm={8}>
            <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 18 }}>
              <Link color="inherit" href="/dashboard" onClick={() => {}}>
                Panel zarządzania
              </Link>
              <Link color="inherit" href="/bank-transfers" onClick={() => {}}>
                Przelewy
              </Link>
              <Typography color="textPrimary">{bankTransfer.title}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item sm={4} style={{ textAlign: 'right' }}>
            <Button variant="outlined" onClick={onBankTransferSave}>
              Zapisz
            </Button>
          </Grid>
        </Grid>

        <Paper variant="outlined">
          <Grid container spacing={3} style={{ padding: 12 }}>
            <Grid item sm={4}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell width={120}>Kontrahent</TableCell>
                    <TableCell>{bankTransfer.from}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={120}>Tytuł operacji</TableCell>
                    <TableCell>{bankTransfer.title}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={120}>Data</TableCell>
                    <TableCell>{moment(bankTransfer.bookDate).format('YYYY-MM-DD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={120}>Kwota</TableCell>
                    <TableCell>{bankTransfer.amount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={120}>Waluta</TableCell>
                    <TableCell>{bankTransfer.currency}</TableCell>
                  </TableRow>
                  {bankTransfer.currency === 'EUR' && (
                    <TableRow>
                      <TableCell width={120}>Kurs NBP</TableCell>
                      <TableCell>
                        {bankTransfer?.exchangeRates?.Mid} ({moment(bankTransfer?.exchangeRates?.EffectiveDate).format('YYYY-MM-DD')})
                      </TableCell>
                    </TableRow>
                  )}
                  {bankTransfer.currency === 'EUR' && (
                    <TableRow>
                      <TableCell width={120}>Kwota PLN po średnim kursie NBP</TableCell>
                      <TableCell>{(bankTransfer?.exchangeRates?.Mid * bankTransfer.amount).toFixed(2)}</TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell width={120}>Status</TableCell>
                    <TableCell>
                      {bankTransfer.status && (
                        <FormControl variant="outlined" size="small">
                          <InputLabel shrink id="status-label">
                            status
                          </InputLabel>
                          <Select
                            id="status"
                            value={bankTransfer.status}
                            onChange={(e) => {
                              handleStatus(e.target.value as string);
                            }}
                            label="Status"
                          >
                            <MenuItem value="booked">ZAKSIĘGOWANY W AVANTIO</MenuItem>
                            <MenuItem value="new">NOWY</MenuItem>
                            <MenuItem value="matched">REZERWACJA DODANA</MenuItem>
                            <MenuItem value="disabled_booking">NIE KSIĘGOWAĆ</MenuItem>
                            <MenuItem value="not_located">REZERWACJA NIEZLOKALIZOWANA</MenuItem>
                            <MenuItem value="nota_obciazeniowa">NOTA OBCIĄŻENIOWA</MenuItem>
                            <MenuItem value="transfer_not_located">PRZELEW NIEZLOKALIZOWANY</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell width={120}>Typ płatności</TableCell>
                    <TableCell>
                      {
                        <FormControl variant="outlined" size="small">
                          <InputLabel shrink id="paymentType-label">
                            płatność
                          </InputLabel>
                          <Select
                            label="płatność"
                            id="paymentType"
                            value={bankTransfer.paymentType}
                            onChange={(e) => {
                              handlePaymentType(e.target.value as string);
                            }}
                          >
                            <MenuItem value="p24">P24</MenuItem>
                            <MenuItem value="exclusive">EXCLUSIVE</MenuItem>
                            <MenuItem value="edomizil">E-DOMIZIL</MenuItem>
                          </Select>
                        </FormControl>
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item sm={8}>
              {isBookingActive && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Localizator</TableCell>
                      <TableCell>BookingDate</TableCell>
                      <TableCell>Arrival Date</TableCell>
                      <TableCell>DepartureDate</TableCell>
                      <TableCell>Total Price</TableCell>
                      <TableCell>Waluta</TableCell>
                      <TableCell>Idź do Avantio</TableCell>
                      <TableCell>Usuń</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bankTransfer.booking.map((booking: any) => (
                      <TableRow key={booking.id}>
                        <TableCell>{booking.Localizator}</TableCell>
                        <TableCell>{moment(booking.ArrivalDate).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{moment(booking.DepartureDate).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{moment(booking.ArrivalDate).format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{booking.TotalPrice}</TableCell>
                        <TableCell>{booking.Currency}</TableCell>
                        <TableCell>
                          <IconButton
                            target="_blank"
                            href={`https://app.avantio.com/index.php?record=${booking.BookingCode}&module=Compromisos&action=DetailView&return_module=Compromisos&return_action=Ajax`}
                          >
                            <LinkIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              onBookingRemove(booking.id);
                            }}
                          >
                            <ClearIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </Grid>
          </Grid>
        </Paper>
        {isBookingActive && (
          <>
            <BookingsFilters filters={filters} />
            <Paper style={{ marginTop: 10 }}>
              <BankTrnsferEditBookings btBookings={bankTransfer.booking} onBookingAdd={onBookingAdd} filters={filters} />
            </Paper>
          </>
        )}
      </Layout>
      <Backdrop open={isLoading} style={{ zIndex: 1000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default BankTransferEdit;
