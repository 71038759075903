import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme, // Fix when Material UI V5 will bee done
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import store from '@Core/redux';
import { I18nextProvider } from 'react-i18next';
import i18n from '@Core/i18n';
import config from '@Core/config';

config();

let theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0F62FE',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: '#ffb144',
      main: '#FFA62B',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#FFF',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"', 'Inter'].join(
      ',',
    ),
  },
});
theme = responsiveFontSizes(theme);

Object.assign(theme, {
  overrides: {
    MUIRichTextEditor: {
      root: {},
      container: {
        margin: 0,
      },
      editorContainer: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      editor: {
        minHeight: theme.spacing(6),
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<div>loading...</div>}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
