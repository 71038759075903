import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import useStyles from './ChangePasswordStyles';
import * as Yup from 'yup';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { setPassword } from '@Core/redux/password/userProfileApi';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';

interface IChangePasswordProps {
  open: boolean;
  handleClose: (event: {}) => void;
}

const ChangePassword: React.FC<IChangePasswordProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const css = useStyles();

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const PasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    newPassword: Yup.string().required('Required'),
    showPassword: Yup.boolean().required('Required'),
  });

  const initialValues = {
    password: '',
    newPassword: '',
    showPassword: false,
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: PasswordSchema,
    onSubmit: values => {
      setPassword({
        password: values.password,
        newPassword: values.newPassword,
      }).then(data => {
        if (data.data) {
          handleClose(data.data);
          dispatch(
            snackbarAction({
              severity: 'success',
              message: 'Password has changed',
              isOpen: true,
            }),
          );
          formik.resetForm();
        } else {
          formik.setFieldError('password', 'Wrong password');
        }
      });
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <FormControl className={css.formControl} variant="outlined" fullWidth>
            <InputLabel className={css.label} htmlFor="password">
              Old Password
            </InputLabel>
            <OutlinedInput
              autoFocus
              id="password"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.password}
              error={!!formik.errors.password && formik.touched.password}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>
              {formik.touched.password && formik.errors.password}
            </FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel className={css.label} htmlFor="newPassword">
              New Password
            </InputLabel>
            <OutlinedInput
              id="newPassword"
              type={values.showPassword ? 'text' : 'password'}
              value={formik.values.newPassword}
              error={!!formik.errors.newPassword && formik.touched.newPassword}
              onChange={formik.handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText error>
              {formik.touched.newPassword && formik.errors.newPassword}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Change Password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
