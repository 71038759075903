import * as React from 'react';
import Layout from 'Layout/Layout';
import { Paper, Grid, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUnpaidBookingsStartedAction, setFilterStartedAction } from '@Core/redux/bankTransfer/bankTransferSlice';
import { AppState } from '@Core/redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import MaterialTable from 'material-table';
import { IBookingFilter } from '@Core/redux/bankTransfer/bankTransferTypes';
interface IBookingsProps {
  btBookings: any[];
  onBookingAdd: (booking: any) => void;
  filters: IBookingFilter;
}

const Bookings: React.FunctionComponent<IBookingsProps> = ({ btBookings, onBookingAdd, filters }) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUnpaidBookingsStartedAction());
  }, []);
  const { isLoading, error, unpaidBookings } = useSelector((state: AppState) => state.bankTransfer);
  console.log(filters);
  return (
    <MaterialTable
      style={{ width: '100%' }}
      page={filters.offset / filters.limit}
      totalCount={filters.count}
      options={{
        headerStyle: {
          zIndex: 0,
        },
        tableLayout: 'auto',
        draggable: false,

        search: false,
        showTitle: false,
        toolbar: false,
        pageSize: filters.pageSize,
      }}
      onChangePage={(page: number, pageSize: number) => {
        console.log(pageSize);

        const offset = page * pageSize;
        dispatch(setFilterStartedAction({ field: 'offset', value: offset }));
      }}
      onChangeRowsPerPage={(pageSize: number) => {
        dispatch(setFilterStartedAction({ field: 'pageSize', value: pageSize }));
        dispatch(setFilterStartedAction({ field: 'limit', value: pageSize }));
      }}
      columns={[
        {
          title: 'Localizator',
          field: 'Localizator',
        },
        {
          title: 'BookingCode',
          field: 'BookingCode',
        },
        {
          title: 'TotalPrice',
          field: 'TotalPrice',
        },
        {
          title: 'Web',
          field: 'Web',
        },
        {
          title: 'LastModifyDateTime',
          field: 'LastModifyDateTime',
        },
        // {
        //   title: 'BookingPayments',
        //   field: 'BookingPayments',
        // },
        {
          title: 'BookingDate',
          field: 'BookingDate',
        },
        {
          title: 'Idź do Avantio',
          sorting: false,
          align: 'center',
          render: (booking) => (
            <IconButton
              target="_blank"
              href={`https://app.avantio.com/index.php?record=${booking.BookingCode}&module=Compromisos&action=DetailView&return_module=Compromisos&return_action=Ajax`}
            >
              <LinkIcon />
            </IconButton>
          ),
        },
        {
          title: 'Dodaj do rezerwacji',
          sorting: false,
          align: 'center',
          render: (booking) => (
            <IconButton
              onClick={() => {
                onBookingAdd(booking);
              }}
            >
              <AddIcon />
            </IconButton>
          ),
        },
      ]}
      data={unpaidBookings.filter((booking) => !btBookings.find((btB) => btB.id === booking.id)).map((o) => ({ ...o }))}
    />
  );
};

export default Bookings;
