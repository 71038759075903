import axios from 'axios';

const login = (data: any) => {
  return axios
    .post('/auth/login', {
      username: data.email,
      password: data.password,
      remember: data.remember,
    })
    .catch((e: any) => {
      throw e.response.data;
    });
};

const logout = () => {
  return axios.post('/auth/logout').catch((e: any) => {
    throw e.response.data;
  });
};

const getAuth = () => {
  return axios.get('/auth/getAuth').catch((e: any) => {
    throw e.response.data;
  });
}

const setOrganizationOwner = (name:string) => {
  return axios.post('/auth/setOrganizationOwner', {
    name
  }).catch((e: any) => {
    throw e.response.data;
  });
}

export default { login, logout, setOrganizationOwner, getAuth };
