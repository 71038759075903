import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
  loginAction,
  userAuthAction,
  userAuthActionDone,
  userAuthActionFailed,
  loginActionFailed,
  logoutAction,
  logoutActionDone,
  logoutActionFailed,
  setOrganizationOwnerAction,
  setOrganizationOwnerActionDone,
  setOrganizationOwnerActionFailed,
} from './authSlice';
import Api from './authApi';
import history from '@Core/history';

function* loginSaga({ payload }: any): SagaIterator {
  try {
    const data = yield call(Api.login, payload);
    yield put(userAuthActionDone(data.data));
    yield call(() => history.push('/bank-transfers'));
  } catch (e) {
    yield put(loginActionFailed(e));
  }
}

function* logoutSaga(): SagaIterator {
  try {
    const data = yield call(Api.logout);
    yield put(logoutActionDone(data.data));
    yield call(() => history.push('/'));
  } catch (e) {
    yield put(logoutActionFailed(e));
  }
}

function* setOrganizationOwnerSaga({ payload }: { payload: { name: string } }): SagaIterator {
  try {
    const data = yield call(Api.setOrganizationOwner, payload.name);
    yield put(setOrganizationOwnerActionDone(data.data));
  } catch (e) {
    yield put(setOrganizationOwnerActionFailed(e));
  }
}

function* getAuthSaga(): SagaIterator {
  try {
    const data = yield call(Api.getAuth);
    yield put(userAuthActionDone(data.data));
  } catch (e) {
    console.log(e);
    yield call(() => history.push('/'));
    yield put(userAuthActionFailed(e));
  }
}

export default function* authSaga() {
  yield takeLatest(loginAction, loginSaga);
  yield takeLatest(logoutAction, logoutSaga);
  yield takeLatest(setOrganizationOwnerAction, setOrganizationOwnerSaga);
  yield takeLatest(userAuthAction, getAuthSaga);
}
