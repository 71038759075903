import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { useHistory } from 'react-router-dom';

interface IMainMenuProps {}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbarHeader: {
    ...theme.mixins.toolbar,
    marginTop: 5,
    marginBottom: -5,
  },
}));

const MainMenu: React.FunctionComponent<IMainMenuProps> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      open={true}
    >
      <div className={classes.toolbarHeader}>
        <Logo style={{ height: 55, width: 60, marginLeft: 16, marginBottom: 5 }} />
      </div>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            history.push('/bank-transfers');
          }}
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Przelewy" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push('/bookings');
          }}
        >
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="Rezerwacje" />
        </ListItem>
        {/* <ListItem
          button
          onClick={() => {
            history.push('/dashboard');
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        {/* <ListItem
          button
          onClick={() => {
            history.push('/users-management/roles');
          }}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Roles" />
        </ListItem> */}
        <ListItem
          button
          onClick={() => {
            history.push('/users-management/users');
          }}
        >
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MainMenu;
