import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  list: {
    minWidth: 200,
  },
  listLink:{
    textDecoration: 'none',
    display: "block",
    color: theme.palette.text.primary
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 16,
  },
}));
