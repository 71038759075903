import * as React from 'react';
import Layout from 'Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileAction } from '@Core/redux/userProfile/userProfileSlice';
interface IDashboardProps {}

const Dashboard: React.FunctionComponent<IDashboardProps> = props => {
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   dispatch(userProfileAction());
  // }, []);
  return <Layout>Dashboard</Layout>;
};

export default Dashboard;
