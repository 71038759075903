import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  Paper,
  Popper,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@Core/redux/auth/authSlice';
import useStyles from './DropdownMenuStyles';
import { Link } from 'react-router-dom';

interface IDropdownMenuProps {
  open: boolean;
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
  frowardRef: any;
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  open,
  handleClose,
  frowardRef,
}) => {
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      frowardRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Popper
      open={open}
      anchorEl={frowardRef.current}
      role={undefined}
      transition
      disablePortal
      placement="left-start"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                className={classes.list}
              >
                <Link to="/profile" className={classes.listLink}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <AccountCircleIcon fontSize="small" />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                </Link>
                <Link to="/settings" className={classes.listLink}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <SettingsIcon fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                </Link>
                <MenuItem onClick={() => dispatch(logoutAction())}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default DropdownMenu;
