import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { UserProfileState } from './userProfileTypes';

const initialState: UserProfileState = {
  user: {
    email: '',
    name: '',
  },
  isLoading: false,
  error: {},
  editable: false,
};

const userProfileSlice = createSlice({
  name: 'USER_PROFILE',
  initialState,
  reducers: {
    userProfileAction(
      state: Draft<UserProfileState>,
      action: PayloadAction<UserProfileState>,
    ) {
      state.isLoading = true;
    },
    userProfileActionDone(
      state: Draft<UserProfileState>,
      action: PayloadAction<{ email: string; name: string }>,
    ) {
      state.isLoading = false;
      state.user = action.payload;
    },
    userProfileActionFailed(
      state: Draft<UserProfileState>,
      action: PayloadAction<UserProfileState>,
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserProfileAction(
      state: Draft<UserProfileState>,
      action: PayloadAction<{ name: string; email: string }>,
    ) {
      state.isLoading = true;
    },
    setUserProfileActionDone(
      state: Draft<UserProfileState>,
      action: PayloadAction<{ email: string; name: string }>,
    ) {
      state.isLoading = false;
      state.user = action.payload;
    },
    setUserProfileActionFailed(
      state: Draft<UserProfileState>,
      action: PayloadAction<UserProfileState>,
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setEditableAction(
      state: Draft<UserProfileState>,
      action: PayloadAction<boolean>,
    ) {
      state.editable = action.payload;
    },
  },
});

export const {
  userProfileAction,
  userProfileActionDone,
  userProfileActionFailed,
  setUserProfileAction,
  setUserProfileActionDone,
  setUserProfileActionFailed,
  setEditableAction,
} = userProfileSlice.actions;
export { initialState };
export default userProfileSlice.reducer;
