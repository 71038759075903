import { createSlice, PayloadAction, Draft } from '@reduxjs/toolkit';
import { RegistrationState } from './registrationTypes';

const initialState: RegistrationState = {
  isLoading: false,
  values: {},
  history: {},
  error: {},
};

const registrationSlice = createSlice({
  name: 'REGISTRATION',
  initialState,
  reducers: {
    registrationAction(
      state: Draft<RegistrationState>,
      action: PayloadAction<RegistrationState>,
    ) {
      state.isLoading = true;
    },
    registrationActionDone(
      state: Draft<RegistrationState>,
      action: PayloadAction<RegistrationState>,
    ) {
      state.isLoading = false;
    },
    registrationActionFailed(
      state: Draft<RegistrationState>,
      action: PayloadAction<RegistrationState>,
    ) {
      state.isLoading = false;
      state.error = action.payload;
    },
    registrationConfirmAction(
      state: Draft<RegistrationState>,
      payload: PayloadAction<any>,
    ) {
      state.isLoading = true;
    },
    registrationConfirmActionDone(
      state: Draft<RegistrationState>,
      payload: PayloadAction<any>,
    ) {
      state.isLoading = false;
    },
    registrationConfirmActionFailed(
      state: Draft<RegistrationState>,
      payload: PayloadAction<any>,
    ) {
      state.isLoading = false;
      state.error = payload.payload;
    },
  },
});

export const {
  registrationAction,
  registrationActionDone,
  registrationActionFailed,
  registrationConfirmAction,
  registrationConfirmActionDone,
  registrationConfirmActionFailed,
} = registrationSlice.actions;
export { initialState };
export default registrationSlice.reducer;
