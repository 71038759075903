import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getBankTransfersStartedAction, getBankTransfersFailedAction, getBankTransfersDoneAction, setFilterStartedAction } from './bankSlice';

import * as Api from './bankApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { bankTransferFilterSelector } from './bankSelector';

function* getBankTransfersSaga(): SagaIterator {
  try {
    const filters = yield select(bankTransferFilterSelector);
    const { data } = yield call(Api.getBankTransfers, filters);
    yield put(getBankTransfersDoneAction(data));
  } catch (e) {
    yield put(getBankTransfersFailedAction({ error: e }));
  }
}

function* setFilterSaga({ payload }: PayloadAction<{ field: string; value: string }>): SagaIterator {
  try {
    const filters = yield select(bankTransferFilterSelector);
    const { data } = yield call(Api.getBankTransfers, filters);
    yield put(getBankTransfersDoneAction(data));
  } catch (e) {
    yield put(getBankTransfersFailedAction({ error: e }));
  }
}

export default function* bankSaga() {
  yield takeLatest(getBankTransfersStartedAction, getBankTransfersSaga);

  yield takeLatest(setFilterStartedAction, setFilterSaga);
}
