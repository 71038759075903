import { Input, InputProps } from '@material-ui/core';
import * as React from 'react';
import useStyles from './InputSmallStyles';
interface IInputSmallProps extends InputProps {}

const InputSmall: React.FunctionComponent<IInputSmallProps> = props => {
  const css = useStyles();
  return <Input className={css.input} {...props} />;
};

export default InputSmall;
