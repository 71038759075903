import { IUnpaidBooking } from '../bankTransfer/bankTransferTypes';

export interface IBankTransfer {
  id: string;
  ingId: string;
  amount: number;
  currency: string;
  title: string;
  bookDate: string;
  from: string;
  accountFrom: string;
  paymentType?: string;
  status?: string;
  booking: IUnpaidBooking[];
  bookedBy: {
    name: string;
    id: string;
    email: string;
  };
  type: string;
}

export enum PaymentType {
  p24 = 'p24',
  exclusive = 'exclusive',
  edomizil = 'edomizil',
}

export enum BankTransferStatus {
  booked = 'booked',
  new = 'new',
  matched = 'matched',
  disabled_booking = 'disabled_booking',
  not_located = 'not_located',
  nota_obciazeniowa = 'nota_obciazeniowa',
  transfer_not_located = 'transfer_not_located',
}

export enum ICurrency {
  PLN = 'PLN',
  EUR = 'EUR',
}

export interface IBankTransferFilter {
  title: string;
  from: string;
  bookDateFrom: string;
  bookDateTo: string;
  amount_from: number | '';
  amount_to: number | '';
  paymentType: PaymentType[];
  avantioBookingId: string;
  bookedBy: string;
  status: BankTransferStatus[];
  currency: ICurrency[];
}

export interface BankState {
  bankTransfers: IBankTransfer[];
  bankTranserFilters: IBankTransferFilter;
  isLoading?: boolean;
  error?: any;
}
