import * as React from 'react';
import {
  Breadcrumbs,
  Link,
  Typography,
  Paper,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  Chip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { BankTransferStatus, IBankTransferFilter, PaymentType } from '@Core/redux/bank/bankTypes';
import { setFilterStartedAction } from '@Core/redux/bank/bankSlice';
import { getSearchUsersAction } from '@Core/redux/usersManagement/users/usersSlice';
import 'react-day-picker/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import { usersSelector } from '@Core/redux/usersManagement/users/usersSelector';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './BankTransfersStyles';
interface IBankTransfersFilterProps {
  filters: IBankTransferFilter;
}

const BankTransfersFilter: React.FunctionComponent<IBankTransfersFilterProps> = ({ filters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleFiltersChange = (e: React.ChangeEvent<{ value: string; name: string }>) => {
    const { name, value } = e.target;
    dispatch(setFilterStartedAction({ field: name, value }));
  };

  const handleDateChange = (name: string, value: string) => {
    dispatch(setFilterStartedAction({ field: name, value }));
  };

  const handleStatusChange = (name: string, value: string) => {
    console.log(value);
    dispatch(setFilterStartedAction({ field: name, value }));
  };

  const users = useSelector(usersSelector);

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    dispatch(getSearchUsersAction(inputValue));
    const data = users.map((user) => ({ value: user.id, label: user.name }));
    callback(data);
  };

  const handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    console.log(inputValue);
    return inputValue;
  };

  function getStyles(value: BankTransferStatus, theme: any) {
    return {
      fontWeight: filters.status.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  function getStylesPayment(value: PaymentType, theme: any) {
    return {
      fontWeight: filters.paymentType.indexOf(value) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  function getPaymentTypeLabel(value: PaymentType) {
    return {
      p24: 'P24',
      exclusive: 'EXCLUSIVE',
      edomizil: 'E-DOMIZIL',
    }[value];
  }
  function getStatusLabel(value: BankTransferStatus) {
    return {
      booked: 'ZAKSIĘGOWANY W AVANTIO',
      new: 'NOWY',
      matched: 'REZERWACJA DODANA',
      disabled_booking: 'NIE KSIĘGOWAĆ',
      not_located: 'REZERWACJA NIEZLOKALIZOWANA',
      nota_obciazeniowa: 'NOTA OBCIĄŻENIOWA',
      transfer_not_located: 'PRZELEW NIEZLOKALIZOWANY',
    }[value];
  }

  return (
    <Grid container spacing={3} style={{ padding: 12 }}>
      <Grid item>
        <Paper variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Data księgowania</TableCell>
                <TableCell>Kwota</TableCell>
                <TableCell>Kontrahent</TableCell>
                <TableCell>Tytuł operacji</TableCell>
                <TableCell>Rodzaj płatności</TableCell>
                <TableCell>Numer rezerwacji</TableCell>
                <TableCell>Kto zaksiegował</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <DayPickerInput
                    value={filters.bookDateFrom}
                    formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                    parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                    format={'YYYY-MM-DD'}
                    onDayChange={(selectedDay) => handleDateChange('bookDateFrom', moment(selectedDay).format('YYYY-MM-DD'))}
                    dayPickerProps={{
                      selectedDays: moment(filters.bookDateFrom, 'YYYY-MM-DD').toDate(),
                    }}
                    component={(props: any) => (
                      <OutlinedInput
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...props}
                        className={classes.root}
                        classes={{ input: classes.input }}
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear date"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDateChange('bookDateFrom', '');
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                    placeholder="od"
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="amount_from"
                    style={{ width: 80 }}
                    value={filters.amount_from}
                    onChange={handleFiltersChange}
                    placeholder="od"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="from"
                    fullWidth
                    value={filters.from}
                    onChange={handleFiltersChange}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="title"
                    fullWidth
                    value={filters.title}
                    onChange={handleFiltersChange}
                  />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" size="small">
                    <Select
                      label="płatność"
                      id="paymentType"
                      multiple
                      value={filters.paymentType}
                      input={<Input id="paymentType-chip" />}
                      renderValue={(selected: any[]) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={getPaymentTypeLabel(value)} />
                          ))}
                        </div>
                      )}
                      onChange={(e) => {
                        handleStatusChange('paymentType', e.target.value as string);
                      }}
                    >
                      <MenuItem value="p24" style={getStylesPayment(PaymentType.p24, theme)}>
                        P24
                      </MenuItem>
                      <MenuItem value="exclusive" style={getStylesPayment(PaymentType.exclusive, theme)}>
                        EXCLUSIVE
                      </MenuItem>
                      <MenuItem value="edomizil" style={getStylesPayment(PaymentType.edomizil, theme)}>
                        E-DOMIZIL
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="avantioBookingId"
                    fullWidth
                    value={filters.avantioBookingId}
                    onChange={handleFiltersChange}
                  />
                </TableCell>
                <TableCell>
                  <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onInputChange={handleInputChange} />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" size="small">
                    <Select
                      id="status"
                      multiple
                      value={filters.status}
                      input={<Input id="status-chip" />}
                      renderValue={(selected: any[]) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={getStatusLabel(value)} />
                          ))}
                        </div>
                      )}
                      onChange={(e) => {
                        handleStatusChange('status', e.target.value as string);
                      }}
                      label="Status"
                    >
                      <MenuItem value="booked" style={getStyles(BankTransferStatus.booked, theme)}>
                        ZAKSIĘGOWANY W AVANTIO
                      </MenuItem>
                      <MenuItem value="new" style={getStyles(BankTransferStatus.new, theme)}>
                        NOWY
                      </MenuItem>
                      <MenuItem value="matched" style={getStyles(BankTransferStatus.matched, theme)}>
                        REZERWACJA DODANA
                      </MenuItem>
                      <MenuItem value="disabled_booking" style={getStyles(BankTransferStatus.disabled_booking, theme)}>
                        NIE KSIĘGOWAĆ
                      </MenuItem>
                      <MenuItem value="not_located" style={getStyles(BankTransferStatus.not_located, theme)}>
                        REZERWACJA NIEZLOKALIZOWANA
                      </MenuItem>
                      <MenuItem value="nota_obciazeniowa" style={getStyles(BankTransferStatus.nota_obciazeniowa, theme)}>
                        NOTA OBCIĄŻENIOWA
                      </MenuItem>
                      <MenuItem value="transfer_not_located" style={getStyles(BankTransferStatus.not_located, theme)}>
                        PRZELEW NIEZLOKALIZOWANY
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <DayPickerInput
                    value={filters.bookDateTo}
                    formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                    parseDate={(date) => moment(date, 'YYYY-MM-DD').toDate()}
                    format={'YYYY-MM-DD'}
                    onDayChange={(selectedDay) => handleDateChange('bookDateTo', moment(selectedDay).format('YYYY-MM-DD'))}
                    dayPickerProps={{
                      selectedDays: moment(filters.bookDateTo, 'YYYY-MM-DD').toDate(),
                    }}
                    component={(props: any) => (
                      <OutlinedInput
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        fullWidth
                        {...props}
                        className={classes.root}
                        classes={{ input: classes.input }}
                        autoComplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="clear date"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDateChange('bookDateTo', '');
                              }}
                              edge="end"
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                    placeholder="do"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    name="amount_to"
                    style={{ width: 80 }}
                    value={filters.amount_to}
                    onChange={handleFiltersChange}
                    placeholder="do"
                  />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" size="small">
                    <Select
                      id="currency"
                      multiple
                      value={filters.currency}
                      input={<Input id="currency-chip" />}
                      renderValue={(selected: any[]) => (
                        <div>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </div>
                      )}
                      onChange={(e) => {
                        handleStatusChange('currency', e.target.value as string);
                      }}
                      label="Waluta"
                    >
                      <MenuItem value="PLN">PLN</MenuItem>
                      <MenuItem value="EUR">EUR</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BankTransfersFilter;
