import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@Core/redux';
import { BankTransferState } from './bankTransferTypes';

export const bankTransferSelector = createSelector(
  (state: AppState) => state.bankTransfer,
  (bank) => bank,
);

export const filterSelector = createSelector(
  (state: AppState) => state.bankTransfer,
  (bank: BankTransferState) => bank.filters,
);
