import React from 'react';
import MainRouter from 'Views/index';

function App() {
  return (
    <>
      <MainRouter />
    </>
  );
}

export default App;
