import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import useStyles from './OrganizationSettingsStyles';
import * as Yup from 'yup';
import {
  DialogContentText,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import { setOrganizationOwnerAction } from '@Core/redux/auth/authSlice';
import { snackbarAction } from '@Core/redux/snackbar/snackbarSlice';

interface IOrganizationSettingsProps {
  open: boolean;
  handleClose: (event: {}) => void;
}

const OrganizationSettings: React.FC<IOrganizationSettingsProps> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const css = useStyles();

  const OrganizationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const initialValues = {
    name: '',
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: OrganizationSchema,
    onSubmit: values => {
      dispatch(setOrganizationOwnerAction({ name: values.name }));
      handleClose({});

      dispatch(
        snackbarAction({
          severity: 'success',
          message: "You've created new organization!",
          isOpen: true,
        }),
      );
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">Create new organization</DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Cześć! Został ostatni krok do rozpoczęcia korzystania z Edumiko!
            Podaj nazwę swojej organizacji. Jeśli tworzysz kursy jako osoba
            fiznyczna podaj swoje imię i nazwisko. Jeśli tworzysz kursy jako
            firma, podaj jej nazwę.
          </DialogContentText>
          <FormControl className={css.formControl} variant="outlined" fullWidth>
            <InputLabel className={css.label} htmlFor="name">
              Name
            </InputLabel>
            <OutlinedInput
              autoFocus
              id="name"
              type="text"
              value={formik.values.name}
              error={!!formik.errors.name && formik.touched.name}
              onChange={formik.handleChange}
            />
            <FormHelperText error>
              {formik.touched.name && formik.errors.name}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" variant="contained">
            Save organization name
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OrganizationSettings;
