import * as React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Badge, IconButton, Toolbar, Typography, Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { logoutAction } from '@Core/redux/auth/authSlice';
import DropdownMenu from '../DropdownMenu';

interface IHeaderProps {}
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
  },
}));

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          Panel zarządzania
        </Typography>
        <div>
          <IconButton
            color="inherit"
            ref={anchorRef}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            <SettingsIcon />
          </IconButton>
          <DropdownMenu
            open={menuOpen}
            handleClose={() => {
              setMenuOpen(!menuOpen);
            }}
            frowardRef={anchorRef}
          />
        </div>
        {/* <Button
          color="default"
          variant="contained"
          onClick={() => {
            dispatch(logoutAction());
          }}
        >
          Logout
        </Button> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
